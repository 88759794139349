:root {
	--main-color: rgb(104, 117, 217);
	--main-color-hover: rgb(104, 117, 217, 0.2);
	--track-color: #ddd;
}

.customSlider {
	/* max width of your slider */
	max-width: 300px;
	/* Optional: Only If you want to center your slider in the page */
	margin: 5px;
}

.customSlider-track {
	/* Top value to align your track to the center of your thumb */
	top: 6px;
	/* thickness of the track */
	height: 2px;
	/* default color of your track */
	background: var(--track-color);
}

.customSlider-track.customSlider-track-0 {
	/* color of the track before the thumb */
	background: grey;
}
.customSlider-thumb {
	cursor: pointer;
	/*color for the thumb */
	background: #080404;
	/* shape of the thumb: circle */
	width: 12px;
	height: 12px;
	border-radius: 100%;
	/* remove default outline when selected */
	outline: none;
    border: 1px solid grey;
}

.customSlider-thumb:hover {
	box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    border: 1px solid rgba(81, 203, 238, 1);
}
:root {
	--main-color: rgb(104, 117, 217);
	--main-color-hover: rgb(104, 117, 217, 0.2);
	--track-color: #ddd;
	/* Default mark color*/
	--mark-color: #aaaaaa;
}
... .customSlider-mark {
	cursor: pointer;
	top: 6px;
	width: 1.5px;
	height: 8px;
	background-color: var(--mark-color);
}
.customSlider-mark.customSlider-mark-before {
	background-color: var(--main-color);
}

.customSlider-mark.customSlider-mark-active {
	display: none;
}
