/* Header Styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    padding-top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 20px;
    margin-top: 25px;
}

.headerContent {
    display: flex;
    align-items: center;
}

.logo {
    
    max-width: 450px;
    margin-bottom: 25px;

}

.logoImage {
    max-width: 15px; 
    position: relative;
    top: 1px;
    padding-right: 15px;
}

.logoText {
    font-size: 17px;
}


/* Mobile adjustments */
@media (max-width: 768px) {

    .header {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
    }

    .headerContent {
        justify-content: center; /* Center content horizontally */
    }

    .headerLinks {
        justify-content: center; /* Center links horizontally */
        margin-top: 15px; /* Add space between header content and links */
        width: 100%; /* Optional: Full width for consistent spacing */
        gap: 20px; /* Increase gap for better visibility */
    }

    .logo {
        max-width: 350px; /* Adjusted main image size */
    }

.logoImage {
    max-width: 25px; 
    position: relative;
    top: 3px;
    padding-right: 15px;
}

    .logoText {
        font-size: 25px; /* Adjusted font size for smaller screens */
    }
}

.mainTitle2 {
    font-size: 20px; /* Adjusted font size for better alignment */
    background: linear-gradient(90deg, #84cfff, #4fbde2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    margin: 0;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
    line-height: 1; /* Helps vertically center text with the logo */
}


/* Header Links */
.headerLinks {
    display: flex;
    gap: 15px;
}

.headerLink {
    color: #4cfaff;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
    font-size: 14px;
    text-decoration: none;

    padding: 5px 10px;

}


.mainContainer {
    display: flex;
    font-family: 'Proto Mono';
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
}

.mainCard {
    max-width: 700px;
    width: 100%;
    text-align: center;
    color: #e0e0e0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

/* Black Button with Pink Text and Neon Pink Glow */
.pinkGlowButton {
    font-family: "Proto Mono";
    background-color: #1a1a1a !important; /* Deep black background */
    color: #e07bfc !important; /* Pink text color */
    font-size: 15px !important;
    padding: 10px 20px !important;
    border: none !important;
    width: 100%;
    margin-top: 15px;
    border-radius: 5px !important;
    text-transform: uppercase !important;

    cursor: pointer !important;
    text-shadow: 0 0 8px rgb(200 0 255 / 80%), 0 0 14px rgba(180, 90, 230, 0.6) !important;    
    box-shadow: 0 0 1px rgba(224, 123, 252, 0.6), 0 0 20px rgba(180, 90, 230, 0.4) !important; /* Pink box glow */

}

/* Black Button with Blue Text and Neon Blue Glow */
.blueGlowButton {
    font-family: "Proto Mono";
    background-color: #1a1a1a !important; /* Deep black background */
    color: #7fa6ff !important; /* Blue text color */
    font-size: 15px !important;
    padding: 10px 20px !important;
    border: none !important;
    width: 100%;
    margin-top: 15px;
    border-radius: 5px !important;
    text-transform: uppercase !important;

    cursor: pointer !important;
    text-shadow: 0 0 8px rgb(0 78 255 / 80%), 0 0 14px rgba(90, 120, 230, 0.6) !important;
    box-shadow: 0 0 1px rgba(127, 166, 255, 0.6), 0 0 20px rgba(90, 120, 230, 0.4) !important; /* Blue box glow */

}



.mainTitle {
    font-size: 20px;


    margin-bottom: 20px;
    text-transform: uppercase;
  background: linear-gradient(90deg, #ff007f, #00bfff); /* pink to blue gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  margin-bottom: 25px;
}


.mainTitle::after {
    content: attr(data-text); /* Duplicate the text */
    position: absolute;
    top: 2px; /* Adjust as needed for shadow position */
    left: 2px; /* Adjust as needed for shadow position */
    z-index: -1; /* Place behind the original text */
    color: rgba(0, 0, 0, 0.3); /* Adjust opacity and color for shadow */
    filter: blur(3px); /* Apply blur to mimic shadow effect */
    background: none; /* Ensure no background color on shadow */
}

.botContainer {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    justify-content: space-around;
    flex-wrap: wrap;
}

.blueButton {
    background-color: #7fa6ff; /* Brighter blue-purple */
    color: #ffffff;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(127, 166, 255, 0.4); /* Soft blue-purple glow */
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;

    &:hover {
        background-color: #658dff; /* Darker shade on hover */
        transform: scale(1.05);
        box-shadow: 0 4px 15px rgba(127, 166, 255, 0.7); /* Brighter blue-purple glow */
    }

    &:active {
        background-color: #526fcc;
        transform: scale(1);
    }
}

.botCard {
    background-color: #121212;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-width: 300px; /* Set max-width for side-by-side */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.botTitle {
    font-size: 20px;
    color: #7fa6ff; /* Brighter blue-purple */
    text-shadow: 0 0 8px rgba(127, 166, 255, 0.8), 0 0 14px rgba(90, 120, 230, 0.6); /* Stronger blue-purple glow */
        text-align: left;

    margin-bottom: 10px;
}

.botDescription {
    font-size: 15px;
    color: #aecbff; /* Brighter, cooler blue-purple */
    text-align: left;
    margin-bottom: 15px;
}

.botTitle2 {
    font-size: 20px;
    color: #e07bfc; /* Bright pink-purple */
    text-shadow: 0 0 8px rgba(224, 123, 252, 0.8), 0 0 14px rgba(180, 90, 230, 0.6); /* Pink-purple glow */
        text-align: left;

    margin-bottom: 10px;
}

.botDescription2 {
    font-size: 15px;
    color: #f3b5ff; /* Softer, pastel pink */
    text-align: left;
    margin-bottom: 15px;
}

/* Launch Button Styles */
.launchButton {
    background-color: #0d0d0d;
    color: #4cfaff;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    a {
        color: inherit;
        text-decoration: none;
    }

    &:hover {
        background-color: #1a1a1a;
        transform: scale(1.05);
    }

    &:active {
        background-color: #0a0a0a;
        transform: scale(1);
    }
}
