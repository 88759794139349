.container {
  background-color: #000;
  color: white;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  gap: 20px;
}

.title {
  border-bottom: solid 1px #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 17px;
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    position: relative;
    top: 6px;
    padding-right: 15px;
  }
}

.full {
  width: 100%;
}

.profileCard {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px 10px 0 10px;
  margin-bottom: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: dashed 1px #fff;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.profileTitle {
  font-size: 13px;
    color: #7fa6ff; /* Brighter blue-purple */
    text-shadow: 0 0 8px rgba(127, 166, 255, 0.8), 0 0 14px rgba(90, 120, 230, 0.6); /* Stronger blue-purple glow */
}

.profileContainer {
  color: #ddd;
  border-radius: 8px;
}

.profileRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.value {
  font-size: 13px;
  color: #fff;

  &.highlight {
    color: #28c0ff;
  }

  &.bag {
    color: #aecbff; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
  }
}

.toggleInput {
  display: none;
}

.toggleSwitch {
  display: inline-block;
}

.toggleLabel {
  display: flex;
  align-items: center;
  width: 45px;
  height: 20px;
  background-color: #ccc;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.1s ease, box-shadow 0.1s ease;
}

.toggleIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 5px;
  top: 2px;
  left: 2px;
  transition: left 0.3s ease;
}

.toggleInput:checked + .toggleLabel {

background-color: #4cfaff;
box-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}

.toggleInput:checked + .toggleLabel .toggleIcon {
  left: 26px;
}

.profileOption {
  background-color: rgb(77, 79, 96);
  border-radius: 5px;
  padding: 15px;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #3a3a3a;
  }
}
