.codes {
  margin-top: 12px;

  .codesTitle {
    margin-top: 20px;
    border-bottom: 1px solid gray;
    padding-bottom: 4px;
  }

  .codesList {
    display: flex;
    flex-direction: column;
    align-items: start;
    list-style: none;
    padding: 0;

    .codesListItem {
      padding: 10px 0;

      &:not(:first-child) {
        border-top: 1px solid grey;
      }

      .codesListItemUsers {
        margin-top: 10px;
        margin-left: 18px;

        .codesListItemUsersTitle {
          margin: 6px 0;
        }

        ul {
          list-style: none;
          padding: 0;
          font-size: 14px;
        }
      }
    }
  }
}
