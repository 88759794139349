.idWLMs {
  flex: 1 1 0%;
  padding: 10px;
}
thead {
  border-bottom: 1px solid grey;
}
th {
  color: grey !important;
  border-bottom: 0px !important;
  font-size: 12px !important;
  padding-top: 5px;
}
th > span {
  color: grey !important;
  font-size: 12px !important;
}
tr {
  color: grey !important;
  font-size: 12px !important;
}
td {
  color: grey !important;
  border-bottom: 0px !important;
  font-size: 12px !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: grey !important;
}
.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
  color: grey !important;
}
.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active:hover {
  color: grey !important;
}
.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon {
  color: grey !important;
}
.css-1db085k-MuiSvgIcon-root {
  color: grey !important;
}
.css-177gid-MuiTableCell-root {
  padding: 0px !important;
}
.css-10dfkli-MuiTableCell-root {
  font-size: 12px !important;
  width: 135px;
}
.css-1ex1afd-MuiTableCell-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.css-1q1u3t4-MuiTableRow-root > :nth-child(2) {
  width: 170px;
}
.css-1q1u3t4-MuiTableRow-root > :last-child {
  width: 170px;
}
.dQWtrc > * + * {
  margin-top: 0px;
  margin-left: 24px;
}
.iECDih {
  position: relative;
}
.iECDih .content {
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 4px;
}
.iECDih .indicator {
  width: 100%;
  height: 1px;
  margin: 0px;
  z-index: 1;
  position: absolute;
}
.ejcTzg {
  height: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.BHLgi {
  transition: transform 150ms ease-in-out 0s;
}
.BHLgi svg {
  transform: scale(1.2);
}
@media (max-width: 1920px) .BHSQC {
  font-size: 14px;
  line-height: 16px;
}
.BHSQC {
  color: rgb(146, 146, 146);
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 8px;
  font-size: 14px;
  line-height: 16px;
}
.ejusMF {
  width: auto;
  height: 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  justify-content: normal;
}
.fOWjjq {
  width: 140px;
  margin-bottom: 8px;
}
.dQWtrb > * + * {
  margin-top: 0px;
  margin-left: 14px;
}
.gPjzRQ {
  width: max-content;
  height: max-content;
}
.dQWtrb > * + * {
  margin-top: 0px;
  margin-left: 14px;
}
.HyzzX {
  transition: transform 150ms ease-in-out 0s;
}
.ejcTzg {
  height: 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
}
.eZyLtz {
  width: 140px;
  position: relative;
}
.eZyLtz .input-prefix {
  top: 4px;
  left: 12px;
  height: 100%;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.eZyLtz input {
  width: 100px;
  height: 100%;
  color: rgb(217, 217, 217);
  border: 1px solid rgb(48, 48, 48);
  background: rgb(8, 4, 4);
  padding-left: 40px;
  border-radius: 4px;
}
.fOWjjq input {
  padding-left: 34px !important;
  font-family: 'Proto Mono' !important;
  margin: 0px;
  height: 30px;
}
.eZyLtz .input-suffix {
  top: 0px;
  z-index: 1;
  right: 12px;
  height: 100%;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.ckzUPf {
  transition: transform 150ms ease-in-out 0s;
}
.ejcTzg {
  height: 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
}
.ckzUPf svg {
  transform: scale(1.2);
}
.HyzzX svg {
  transform: scale(1.2);
}
.egzFid {
  width: 100%;
  height: 100%;
}
.esJunV {
  width: 100%;
  height: 100%;
}
.css-hbtnrh-MuiTableCell-root {
  padding-top: 5px !important;
}
.rangeslider {
  display: flex;
  alignitems: center;
  border-radius: 5px;
  border: 1px solid grey;
  width: max-content;
}
input[type='range' i] {
  width: 60px;
  cursor: pointer;
}
.collection-main {
  height: 100%;
  display: flex;
  padding: 24px 12px 0px;
  position: relative;
  background: rgb(8, 4, 4);
  flex-direction: column;
  overflow-y: hidden;
}

element.style {
  left: -16px;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.kehMTM {
  top: 12px;
  width: 16px;
  height: 48px;
  border: 1px solid rgb(48, 48, 48);
  z-index: 999;
  display: flex;
  position: absolute;
  background: rgb(8, 4, 4);
  -webkit-box-align: center;
  align-items: center;
  border-radius: 4px;
  -webkit-box-pack: center;
  justify-content: center;
}

.kehMTM.kehMTM-left-open {
  left: -16px;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.kehMTM.kehMTM-left-close {
  left: 0px;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.kehMTM.kehMTM-right-open {
  right: -16px;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.kehMTM.kehMTM-right-close {
  right: 0px;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.vKjmN {
  transform: rotate(90deg);
  transition: transform 150ms ease-in-out 0s;
}
.ejcTzg {
  height: 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
}
.vKjmN svg {
  transform: scale(1.2);
}
.eAPkgd {
  transform: rotate(270deg);
  transition: transform 150ms ease-in-out 0s;
}
.ejcTzg {
  height: 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
}
.eAPkgd svg {
  transform: scale(1.2);
}
