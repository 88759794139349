.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-wrapper {
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(31, 35, 48);
}

/* Track on hover */
::-webkit-scrollbar-track:hover {
  background: #ddd;
}

/* Set the style of the scrollbar */
::-webkit-scrollbar-corner {
  background: #f1f1f1;
}
