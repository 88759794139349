.hJYBpF {
	height: 36px;
	cursor: pointer;
	padding: 0px 8px;
	background: transparent;
	border-color: rgb(255, 135, 0);
	border-style: solid;
	border-width: 1px;
	border-radius: 4px;
}
.iqwNDd {
	color: rgb(255, 135, 0);
	font-weight: 700;
	text-transform: uppercase;
	margin-right: 16px;
}
.hJYBpF * {
	fill: rgb(255, 135, 0) !important;
	color: rgb(255, 135, 0) !important;
	font-family: "Proto Mono" !important;
    font-size: 12px;
}
@media (max-width: 1920px) .cthsiW {
	font-size: 12px;
	line-height: 14px;
}
.cthsiW {
	color: rgb(255, 135, 0);
	font-weight: 700;
	padding-right: 6px;
}
.bfPWzQ svg {
	transform: scale(1.2);
}
