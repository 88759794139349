.tabs-ui-component ul {
  display: flex;
  gap: 24px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.tabs-ui-component li {
  display: flex;
  gap: 8px;
  align-items: center;
  color: rgb(146, 146, 146);
  cursor: pointer;
  position: relative;
}

.tabs-ui-component li.tabs-ui-component-active {
  color: rgb(246, 174, 45);
}

.tabs-ui-component
  li.tabs-ui-component-active.tabs-ui-component-active-indicator::after {
  content: '';
  width: 100%;
  height: 1px;
  z-index: 1;
  position: absolute;
  bottom: -17px;
  background: rgb(246, 174, 45);
  box-shadow: rgb(246, 174, 45) 0px 0px 8px 0.5px;
}

.tabs-ui-component li.tabs-ui-component-active svg {
  fill: rgb(246, 174, 45);
}

.tabs-ui-component li.tabs-ui-component-active span {
  text-shadow: rgb(246, 174, 45) 0px 0px 16px;
}
