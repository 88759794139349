.container {
  min-height: 100vh;
  margin-top: 25px;
}

body {
  background-color: #000;
  font-family: 'Proto Mono', monospace;
  color: white;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.profilesContainer {
  width: 100%;
  max-width: 600px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header {
  border-bottom: solid 1px #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 17px;
  display: flex;
  align-items: center;
}

.customToast {
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
    color: #4cfaff;
    padding-left: 15px;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}

.backIcon {
  width: 24px;
  height: 24px;
  position: relative;
  top: 6px;
  padding-right: 15px;
}

.profileCard {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px 20px 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: dashed 1px #fff;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.profileTitle {
  font-size: 13px;

    color: #7fa6ff; /* Brighter blue-purple */
    text-shadow: 0 0 8px rgba(127, 166, 255, 0.8), 0 0 14px rgba(90, 120, 230, 0.6); /* Stronger blue-purple glow */
}

.list {
  list-style-type: none;
  padding-left: 2px;
  margin: 0;

  li {
    padding-bottom: 12px;

    color: #aecbff; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
  }
}
.iconContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.customToast {
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
    color: #4cfaff;
    padding-left: 15px;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}

ul li:last-child {
  padding-bottom: 0;
}

.robotIcon {
  width: 15px;
  height: 15px;
  position: relative;
  cursor: pointer;
}

.profileContainer {
  color: #ddd;
  border-radius: 8px;
}

.profileRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.value {
  font-size: 0.8em;
  color: #fff;

  &.positive {
    text-shadow: 0 0 16px rgba(130, 143, 255, 0.5);
  }

  &.negative {
    color: #ff4444;
  }



  &.highlight {
    color: #28c0ff;
  }

  &.address {
    color: #4cfaff;
  }

  &.bag {
    color: #aecbff; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
  }
}

.profileOption {
  background-color: rgb(65, 73, 150);
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #3a3a3a;
  }
}
