.simbuysContainer {
  background-color: #000;
  color: white;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 15px 0;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  gap: 20px;
}

.buysCard {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 10px;
  width: 95%;

  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: visible; /* Ensure children are visible beyond bounds */
}

.buysCard.collapsed {
  padding: 10px;
    width: 95%;

}

.buysHeader {
  display: flex;
  align-items: center;
  color: #ddd;
  font-size: 14px;
  text-shadow: rgba(130, 143, 255) 0px 0px 16px;
}

.buysHeader.withBorder {
  border-bottom: dashed 1px #393939;
  padding-bottom: 8px;
  margin-bottom: 15px;
}

.initial {
  opacity: 0.8;  
}

.p {
  font-size: 13px;
  text-shadow: rgb(0 255 10) 0px 0px 16px; 
  color: rgb(0 255 10);
}

.n {
  font-size: 13px;
  text-shadow: rgb(255, 34, 45) 0px 0px 16px; 
  color: rgb(255 5 17);
}


.buysTitle {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
    color: #7fa6ff; /* Brighter blue-purple */
    text-shadow: 0 0 8px rgba(127, 166, 255, 0.8), 0 0 14px rgba(90, 120, 230, 0.6); /* Stronger blue-purple glow */

}

.buysContainer {
  background-color: #242424;
  color: #ddd;
  padding: 10px 10px 5px 10px;
  margin: 10px auto;
  border-radius: 8px;
  margin-bottom: 25px;
  position: relative;
  overflow: visible; /* Ensures visibility of absolutely positioned elements */
  margin-bottom: 20px;
}

.buysContainer:last-child {
  margin-bottom: 0;
}

.iconCircle {
  position: absolute;
  top: -10px;
  right: 0px; /* Adjust if necessary */
  background-color: #3a3a3a;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 10;
}

.buysRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px dashed #333;
}

.buysRow:last-child {
  border-bottom: none;
}

.valueContainer {
  display: flex;
  align-items: center;
}

.icon {
  width: 15px;
  height: 15px;
  margin-left: 8px;
  position: relative;
  bottom: 1px;
  cursor: pointer;
}

.label, .value {
  font-size: 13px;
  color: #fff;
}

.value.positive {
  color: #fff;
  text-shadow: rgba(130, 143, 255) 0px 0px 16px;
}

.value.highlight {


color: #4cfaff; /* Bright, cool cyan */
text-shadow: 
    0 0 8px rgba(76, 250, 255, 0.8),  /* Strong cyan glow */
    0 0 12px rgba(130, 180, 255, 0.5); /* Blue-purple accent glow */

    

}


.value.address {
    color: #aecbff; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
}


.customToast {
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
    color: #4cfaff;
    padding-left: 15px;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}

.value.bagz {
      color: #fff; /* Brighter, cooler blue-purple */
    text-shadow: 
        0 0 16px rgba(130, 180, 255, 0.7),       /* Primary blue-purple glow */
        0 0 10px rgba(246, 174, 45, 0.5),        /* Warm golden accent */
        0 0 6px rgba(100, 120, 180, 0.6);        /* Subtle deeper blue for balance */

}

.red {
font-size: 13px;
      color: rgb(255, 5, 17); /* Bright red */
    text-shadow: 
        0 0 16px rgba(255, 34, 45, 0.8),       /* Primary red glow */
        0 0 10px rgba(255, 100, 120, 0.5),     /* Soft pink-red accent */
        0 0 6px rgba(130, 180, 255, 0.4);      /* Subtle blue-purple for balance */

}

.green {
font-size: 13px;
      color: rgb(0, 255, 10); /* Bright neon green */
    text-shadow: 
        0 0 16px rgba(0, 255, 10, 0.8),        /* Primary green glow */
        0 0 10px rgba(100, 255, 100, 0.5),     /* Softer lime-green accent */
        0 0 6px rgba(130, 180, 255, 0.4);      /* Cool blue accent for balance */


}

.value.bag {
color: #b3ffff; /* Ultra-bright cyan */
text-shadow: 
    0 0 12px rgba(179, 255, 255, 1),  /* Intense cyan glow */
    0 0 24px rgba(179, 235, 255, 0.8); /* Softer light blue accent glow */
}

.iconWrapper {
  display: flex;
  align-items: center;
  margin-left: 8px;
  transition: transform 0.3s ease;
}


.collapseContent.hide + .buysHeader {
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.collapseContent {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.5s ease, opacity 0.5s ease;
}

.collapseContent.show {
  height: auto;
  opacity: 1;
}

.collapseContent.hide {
  max-height: 0;
  opacity: 0;
}
