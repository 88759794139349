.container {
  background: #444355;
  border-radius: 30px;
  width: 80px;
  height: 30px;
  position: relative;
  transition: 300ms;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .indicator {
    width: 20px;
    height: 20px;
    background: white;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    transition: 300ms;
  }

  .text {
    font-weight: bold;
    margin-left: 4px;
    color: white;
  }

  &.active {
    background: #21ce6d;

    .indicator {
      left: 55px;
    }

    .text {
      margin-left: 0px;
      margin-right: 8px;
    }
  }
}
