
.walletsContainer {
  width: 100%;
  max-width: 600px;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;


  min-height: 100vh;

}


.customToast {
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
    color: #4cfaff;
    padding-left: 15px;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}


.header {
  border-bottom: solid 1px #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 17px;
  display: flex;
  align-items: center;
}

.backIcon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.walletContainer {
  background-color: #1a1a1a;
  color: #ddd;
  padding: 20px 10px 5px 10px;
  border-radius: 8px;
  margin-bottom: 25px;
  position: relative;
  
    .iconCircle {
    position: absolute;
    top: -10px;
    right: 0;
    background-color: #3a3a3a; /* Circle background color */
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

}

.walletHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: dashed 1px #fff;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.walletTitle {
  font-size: 13px;
    color: #7fa6ff; /* Brighter blue-purple */
    text-shadow: 0 0 8px rgba(127, 166, 255, 0.8), 0 0 14px rgba(90, 120, 230, 0.6); /* Stronger blue-purple glow */
}

.icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: relative;
  bottom: 1px;
}

.walletRow {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #fff;
  padding-bottom: 10px;
}

.walletRow:last-child {
  border-bottom: none;
}

.walletAddress {
  font-size: 11px;
    color: #aecbff; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
}

.value {
  font-size: 13px;
  color: #fff;

  &.positive {
    text-shadow: rgba(130, 143, 255) 0px 0px 16px;
  }

  &.negative {
    color: #ff4444;
  }

  &.highlight {
    color: #28c0ff;
  }

  &.address {
    color: #4cfaff;
  }

  &.bag {
    text-shadow: rgb(246, 174, 45) 0px 0px 16px;
  }
}

.customToast {
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
    color: #4cfaff;
    padding-left: 15px;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}

.button, .button:hover {
  background-color: #1a1a1a;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  width: 100%;
}


.saveButton {
     width: 100%;
    padding: 10px 10px;
    font-size: 13px;
    color: #1c1c1c;
    background-color: #789fff;
    border: none;
    border-radius: 3px;
    margin-top: 15px;
    cursor: pointer;
    font-family: "Proto Mono", monospace;
    text-transform: uppercase;
    text-shadow: 0 0 4px rgba(100, 150, 255, 0.8), 0 0 6px rgba(150, 200, 255, 0.6); /* Pure blue glow */
    box-shadow: 0 0 10px rgba(100, 150, 255, 0.8), 0 0 16px rgba(150, 200, 255, 0.6); /* Updated shadow */
   
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.disclaimer {
  font-size: 11px;
  padding-top: 15px;
      color: #4cfaff;

    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}
