.button {
  border-radius: 4px;
  background: rgb(61 72 170);
  padding: 4px 16px;
  border: none;
  color: #fff;
  box-sizing: border-box;
  font-size: 14px;
  font-family: inherit;
  transition: 300ms;

  &:hover {
    /*background: rgba(61, 72, 170, 0.9);*/
  }
}
