body {
  background-color: #000;
  font-family: 'Proto Mono', monospace;
  color: white;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.mainContainer {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}


.mainTitle {
  font-size: 20px;
  color: #fff;
  margin-bottom: 25px;
  font-family: 'Proto Mono', monospace;
  
  /* Horizontal gradient text with pink-to-blue hacker vibe */
  background: linear-gradient(90deg, #ff007f, #00bfff); /* pink to blue gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;


}

.customToast {
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
    color: #4cfaff;
    padding-left: 15px;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}

/* Keyframes for subtle flicker effect */
@keyframes neonFlicker {
  0%, 80%, 100% {
    text-shadow: 
      0 0 8px rgba(255, 0, 127, 0.7),
      0 0 12px rgba(0, 191, 255, 0.5);
  }
  85% {
    text-shadow: none;
  }
}


.mainUser {
  font-size: 15px;
  color: #fff;
margin-top: 25px;
    color: #4cfaff;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}

.loadingOverlay {
  position: fixed; /* Fixed position to cover the entire screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above all other elements */
  color: white;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.button {
  background-color: #0d0d0d; /* Darker, more intense background */
  font-family: 'Proto Mono', monospace;
  
    color: #4cfaff;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);

  border-radius: 5px;
  padding: 15px;

  margin: 8px 0;
  width: 100%;
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.header {
    color: #4cfaff;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);


}
