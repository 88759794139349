.css-1mdmsw7 {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	font-weight: 400;
	text-align: left;
	-webkit-font-smoothing: antialiased;
	color: #b1b6c6;
	font-size: 12px;
	line-height: 1.2;
	box-sizing: border-box;
	position: fixed;
	z-index: 100;
	left: 0px;
	top: 62px;
	height: calc(100vh - 62px);
	transform: translateZ(0px);
	border-right: 1px solid rgb(48, 48, 48);
	border-top: 1px solid rgb(48, 48, 48);
	transition: all 250ms ease 0s;
}
.css-12khlxv {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	font-weight: 400;
	text-align: left;
	-webkit-font-smoothing: antialiased;
	color: #b1b6c6;
	font-size: 12px;
	line-height: 1.2;
	box-sizing: border-box;
	position: relative;
	height: 56%;
	padding: 30px 20px;
}
.css-109c0pv {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	font-weight: 400;
	text-align: left;
	-webkit-font-smoothing: antialiased;
	color: #b1b6c6;
	font-size: 12px;
	line-height: 1.2;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden auto;
}
.label {
	color: rgb(177, 182, 198);
	margin-bottom: 10px;
	font-size: 12px;
	font-weight: 800;
	font-style: normal;
}
.label-wrap {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	width: 100%;
}
.label-right {
	cursor: pointer;
	color: rgb(255, 176, 24);
	font-weight: 500;
	font-style: normal;
	text-transform: none;
}
.input-wrap {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	height: 40px;
	min-height: 40px;
	/* width: 100%; */
	padding: 0px 5px 0px 15px;
	border-radius: 6px;
	border: 1px solid transparent;
	background: rgba(15, 17, 26, 0.55);
	transition: background 0.1s ease 0s;
}
.input-wrap input,
.input-wrap textarea,
.input-wrap input:focus,
.input-wrap textarea:focus,
.input-wrap input:hover,
.input-wrap textarea:hover,
.input-wrap input:active,
.input-wrap textarea:active {
	outline: none;
	border: none;
	background: none;
}
.input-wrap input,
.input-wrap textarea {
	width: 100%;
	height: 100%;
	color: rgb(255, 255, 255);
	font-weight: 700;
	font-style: normal;
	font-size: 14px;
}
.accordion {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	padding-right: 12px;
	font-size: 14px;
	height: 40px;
	min-height: 40px;
	border-radius: 8px;
	color: rgb(255, 255, 255);
	transition: all 0.1s ease 0s;
	font-weight: 500;
	font-style: normal;
	cursor: pointer;
	user-select: none;
}
.expand-icon {
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	white-space: nowrap;
	transition: all 0.1s ease 0s;
	appearance: none;
	border: none;
	letter-spacing: 0.5px;
	cursor: pointer;
	user-select: none;
	border-radius: 6px;
	font-weight: 700;
	font-style: normal;
	font-size: 12px;
	text-transform: uppercase;
	color: rgb(255, 255, 255);
	background: rgba(203, 215, 255, 0.075);
	height: 28px;
	width: 30px;
	padding: 0px;
	position: relative;
	left: 6px;
	margin-left: auto;
}
.css-1f3aqrv {
	fill: currentcolor;
	width: 17px;
	min-width: 17px;
	height: auto;
}
.toggle-switch {
	position: relative;
	display: inline-block;
	width: 65px;
	height: 20px;
	margin-top: 5px;
}
.toggle-switch input[type="checkbox"] {
	display: none;
}
.toggle-switch .switch {
	position: absolute;
	cursor: pointer;
	background-color: #080404;
	border-radius: 25px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: background-color 0.2s ease;
	border: 1px solid grey;
}
.toggle-switch .switch::before {
	position: absolute;
	content: "";
	left: -1px;
	top: -1px;
	width: 20px;
	height: 20px;
	background-color: #aaa;
	border-radius: 50%;
	transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
	transform: translateX(47px);
	background-color: #6699cc;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
	background-color: #080404;
}
.buy-crypto {
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	white-space: nowrap;
	transition: all 0.1s ease 0s;
	appearance: none;
	border: none;
	letter-spacing: 0.5px;
	cursor: pointer;
	user-select: none;
	height: 40px;
	padding: 0px 20px;
	border-radius: 8px;
	font-weight: 800;
	font-style: normal;
	font-size: 14px;
	text-transform: uppercase;
	color: rgb(255, 255, 255);
}
.buy-crypto:not(:disabled):hover {
	background: rgba(203, 215, 255, 0.1);
}
.stepbar-wrap {
	border: 1px solid #707070;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
	margin-top: 20px;
	height: 30px;
}
.button-minus {
	border-right: 1px solid #707070;
	background: #242936;
	width: 30px;
	height: 100%;
	color: rgb(255, 93, 0);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}
.button-plus {
	border-left: 1px solid #707070;
	background: #242936;
	width: 30px;
	height: 100%;
	color: rgb(255, 93, 0);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}
.step-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 10px;
	padding-left: 10px;
	font-size: 12px;
	width: -webkit-fill-available;
}
.amount-wrap {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 30px;
}
.amount-btn-wrap {
	width: 60px;
	height: 30px;
	border-radius: 8px;
	border: 1px solid #707070;
	background: black;
	display: flex;
	flex-direction: row;
	margin-left: 15px;
	font-size: 12px;
	cursor: pointer;
}
.amount-left-btn {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.amount-right-btn {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.pepe-input {
	border: 1px solid #707070;
	border-radius: 8px;
	min-height: 22px;
	padding: 5px;
	background: #080404;
	color: white;
	font-family: "Proto Mono" !important;
}
.amount-input {
	border: 1px solid #707070;
	border-radius: 8px;
	min-height: 22px;
	padding: 5px;
	background: #242936;
	color: white;
	width: 110px;
}
.snipe-btn {
	height: 30px;
	border-radius: 8px;
	border: 1px solid #ff5d00;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	margin-top: 20px;
	cursor: pointer;
	color: #ff5d00;
	padding-left: 20px;
	padding-right: 20px;
	font-family: "Proto Mono" !important;
}
.snipe-btn-right {
	position: relative;
	width: 80px;
	height: 30px;
	display: inline-flex;
	margin-top: 20px;
	color: #fff;
}
.snipe-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.statistic {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
	padding-right: 10px;
}
.gnLMRO {
	transition: transform 150ms ease-in-out 0s;
}
.ejcTzg {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	flex-direction: row;
	-webkit-box-pack: center;
	justify-content: center;
}
.gnLMRO svg {
	transform: scale(1.35);
}
@media (max-width: 1920px) .iMtIGg {
	font-size: 14px;
	line-height: 16px;
}
.iMtIGg {
	color: rgb(173, 226, 93);
	text-shadow: currentcolor 0px 0px 16px;
	font-size: 12px;
	line-height: 16px;
}
@media (max-width: 1920px) .eXWcRH {
	font-size: 14px;
	line-height: 16px;
}
.eXWcRH {
	color: rgb(146, 146, 146);
	font-size: 12px;
	line-height: 16px;
}
