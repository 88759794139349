.activity-wrap {
  display: flex;
  padding: 24px 12px 0px;
  position: relative;
  background: rgb(8, 4, 4);
  flex-direction: column;
  overflow: hidden;
}
.hbhzdg {
  width: auto;
  height: auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  justify-content: normal;
}
.BHLgi {
  transition: transform 150ms ease-in-out 0s;
}
.ejcTzg {
  height: 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
}
@media (max-width: 1920px) .fPNLtw {
  font-size: 14px;
  line-height: 16px;
}
.fPNLtw {
  color: grey;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
}
.xReQq > * + * {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 8px;
}
.BHLgi svg {
  transform: scale(1.2);
}
