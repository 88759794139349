.diszdL {
  width: 100%;
  height: 100%;
  transition: all 250ms ease 0s;
  display: grid;
  gap: 1px;
  background: rgb(48, 48, 48);
  /* grid-template:
		"COLLECTION_OVERLINE COLLECTION_OVERLINE COLLECTION_OVERLINE" minmax(
			84px,
			84px
		)
		"COLLECTION_FILTERS_AND_TRAITS COLLECTION_MAIN COLLECTION_ACTIVITY" minmax(
			136px,
			136px
		)
		"COLLECTION_FILTERS_AND_TRAITS COLLECTION_MAIN COLLECTION_ACTIVITY" minmax(
			0px,
			1fr
		)
		"COLLECTION_FILTERS_AND_TRAITS COLLECTION_MAIN COLLECTION_CHARTS" minmax(
			0px,
			1fr
		)
		"COLLECTION_FILTERS_AND_TRAITS COLLECTION_ACTIONBAR COLLECTION_CHARTS" min-content
		"COLLECTION_UNDERLINE COLLECTION_UNDERLINE COLLECTION_UNDERLINE" minmax(
			32px,
			32px
		)
		/ minmax(285px, 2.15fr) minmax(0px, 7fr) minmax(360px, 2.85fr); */
}
.bFqwZU {
  width: 100%;
  height: 84px;
  /* grid-area: COLLECTION_OVERLINE / COLLECTION_OVERLINE / COLLECTION_OVERLINE /
		COLLECTION_OVERLINE; */
  background: rgb(8, 4, 4);
  transition: all 250ms ease 0s;
}
.hwJrWR {
  /* grid-area: COLLECTION_FILTERS_AND_TRAITS / COLLECTION_FILTERS_AND_TRAITS /
		COLLECTION_FILTERS_AND_TRAITS / COLLECTION_FILTERS_AND_TRAITS; */
  background: rgb(8, 4, 4);
  transition: all 250ms ease 0s;
}
.gXMROr {
  grid-area: COLLECTION_MAIN / COLLECTION_MAIN / COLLECTION_MAIN /
    COLLECTION_MAIN;
  background: rgb(8, 4, 4);
  transition: all 250ms ease 0s;
  height: inherit;
}
.actionbar {
  display: flex;
  padding: 24px;
  position: relative;
  background: rgb(8, 4, 4);
  flex-direction: row;
  grid-area: COLLECTION_ACTIONBAR / COLLECTION_ACTIONBAR / COLLECTION_ACTIONBAR /
    COLLECTION_ACTIONBAR;
  justify-content: space-between;
  transition: all 250ms ease 0s;
  border-top: 1px solid rgb(48, 48, 48);
}
.dYfQsT {
  grid-area: COLLECTION_CHARTS / COLLECTION_CHARTS / COLLECTION_CHARTS /
    COLLECTION_CHARTS;
  background: rgb(8, 4, 4);
  transition: all 250ms ease 0s;
}
.dhSLtB {
  grid-area: COLLECTION_ACTIVITY / COLLECTION_ACTIVITY / COLLECTION_ACTIVITY /
    COLLECTION_ACTIVITY;
  background: rgb(8, 4, 4);
  transition: all 250ms ease 0s;
  height: 56%;
}
.ixiwnO {
  grid-area: COLLECTION_UNDERLINE / COLLECTION_UNDERLINE / COLLECTION_UNDERLINE /
    COLLECTION_UNDERLINE;
  background: rgb(8, 4, 4);
  transition: all 250ms ease 0s;
}
.hbhzdg {
  width: auto;
  height: auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  justify-content: normal;
}
.ejusMF {
  width: auto;
  height: 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  justify-content: normal;
}
.egzFid {
  width: 100%;
  height: 100%;
}
.iECDih {
  position: relative;
}
button {
  margin: 0;
  border: none;
  cursor: pointer;
  padding: 0;
  background: none;
}
.dQWtrc > * + * {
  margin-top: 0px;
  margin-left: 24px;
}
.iECDih .content {
  width: 100%;
  margin: 0px auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  row-gap: 4px;
}
.iECDih.active.indicator-bottom .indicator {
  bottom: -17px;
}
.iECDih.active .indicator {
  color: rgb(246, 174, 45);
  background: rgb(246, 174, 45);
  box-shadow: currentcolor 0px 0px 8px 0.5px;
}
.iECDih .indicator {
  width: 100%;
  height: 1px;
  margin: 0px;
  z-index: 1;
  position: absolute;
}
.iECDih .content {
  width: 100%;
  margin: 0px auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  row-gap: 4px;
}
.iECDih .indicator {
  width: 100%;
  height: 1px;
  margin: 0px;
  z-index: 1;
  position: absolute;
}
.ckzUPf {
  transition: transform 150ms ease-in-out 0s;
}
.ejcTzg {
  height: 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
}
@media (max-width: 1920px) .hbeURA {
  font-size: 14px;
  line-height: 16px;
}
.hbeURA {
  color: rgb(246, 174, 45);
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 8px;
  text-shadow: currentcolor 0px 0px 16px;
  font-size: 14px;
  line-height: 16px;
}
.ckzUPf svg {
  transform: scale(1.2);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  font-weight: 400;
}
.diszdL.left-collapsed:not(.media-xs) {
  grid-template-areas:
    'COLLECTION_OVERLINE COLLECTION_OVERLINE'
    'COLLECTION_MAIN COLLECTION_ACTIVITY'
    'COLLECTION_MAIN COLLECTION_ACTIVITY'
    'COLLECTION_MAIN COLLECTION_CHARTS'
    'COLLECTION_ACTIONBAR COLLECTION_CHARTS'
    'COLLECTION_UNDERLINE COLLECTION_UNDERLINE';
  grid-template-columns: 1fr 0fr;
  transition: all 250ms ease 0s;
}
.diszdL.left-collapsed:not(.media-xs) #COLLECTION_FILTERS_AND_TRAITS {
  display: none;
  transition: all 250ms ease 0s;
}
.eAPkgd {
  transform: rotate(270deg);
  transition: transform 150ms ease-in-out 0s;
}
.diszdL.right-collapsed:not(.media-xs) {
  grid-template-areas:
    'COLLECTION_OVERLINE COLLECTION_OVERLINE'
    'COLLECTION_FILTERS_AND_TRAITS COLLECTION_MAIN'
    'COLLECTION_FILTERS_AND_TRAITS COLLECTION_MAIN'
    'COLLECTION_FILTERS_AND_TRAITS COLLECTION_MAIN'
    'COLLECTION_FILTERS_AND_TRAITS COLLECTION_ACTIONBAR'
    'COLLECTION_UNDERLINE COLLECTION_UNDERLINE';
  grid-template-columns: minmax(285px, 2.15fr) minmax(0px, 9.85fr);
  transition: all 250ms ease 0s;
}
.diszdL.both-collapsed:not(.media-xs) {
  grid-template-areas:
    'COLLECTION_OVERLINE'
    'COLLECTION_MAIN'
    'COLLECTION_MAIN'
    'COLLECTION_MAIN'
    'COLLECTION_ACTIONBAR'
    'COLLECTION_UNDERLINE';
  grid-template-columns: 1fr;
  transition: all 250ms ease 0s;
}
.diszdL.both-collapsed:not(.media-xs) #COLLECTION_CHARTS,
.diszdL.both-collapsed:not(.media-xs) #COLLECTION_ACTIVITY,
.diszdL.both-collapsed:not(.media-xs) #COLLECTION_FILTERS_AND_TRAITS {
  display: none;
  transition: all 250ms ease 0s;
}
.diszdL.right-collapsed:not(.media-xs) #COLLECTION_CHARTS,
.diszdL.right-collapsed:not(.media-xs) #COLLECTION_ACTIVITY {
  display: none;
  transition: all 250ms ease 0s;
}

.css-1mdmsw7 {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-weight: 400;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  color: #b1b6c6;
  font-size: 12px;
  line-height: 1.2;
  box-sizing: border-box;
  position: fixed;
  z-index: 100;
  left: 0px;
  top: 62px;
  height: calc(100vh - 62px);
  transform: translateZ(0px);
  border-right: 1px solid rgb(48, 48, 48);
  transition: all 250ms ease 0s;
}
.css-yl3y1i {
  display: flex;
  flex-direction: column;
  transition: all 250ms ease 0s;
  height: calc(100vh - 62px);
}
@media (min-width: 768px) .css-yl3y1i {
  padding-left: 345px;
}
.css-mhwm4s {
  position: fixed;
  top: 62px;
  right: 0px;
  height: calc(100vh - 62px);
  transform: translateZ(0px);
  transition: all 250ms ease 0s;
  border: 1px solid rgb(48, 48, 48);
}
.button {
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  border: none;
}
button:active {
  transform: translateY(0.5px);
}
.css-11w94w9-MuiTableCell-root {
  font-family: 'Proto Mono' !important;
}
.css-10dfkli-MuiTableCell-root {
  font-family: 'Proto Mono' !important;
}
.css-10cbwbc-MuiTableRow-root {
  font-family: 'Proto Mono' !important;
}
.css-11w94w9-MuiTableCell-root {
  font-family: 'Proto Mono' !important;
}
.css-1ex1afd-MuiTableCell-root {
  font-family: 'Proto Mono' !important;
}
.css-1ex1afd-MuiTableCell-root {
  text-align: center !important;
}
.css-10dfkli-MuiTableCell-root {
  text-align: center !important;
}
