.header-wrap {
    width: -webkit-fill-available;
	height: 62px;
	margin: 0px auto;
	display: flex;
	z-index: 1;
	background: rgb(8, 4, 4);
	align-items: center;
	padding-left: 24px;
	padding-right: 24px;
	border-bottom: 1px solid rgb(48, 48, 48);
	justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
}
.ivoMik {
	width: auto;
	height: auto;
	display: flex;
	align-items: normal;
	flex-direction: row;
	justify-content: normal;
}
.fBtQpu {
	height: 36px;
	position: relative;
}
.hbhzdg {
	width: auto;
	height: auto;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	flex-direction: row;
	justify-content: normal;
}
.fBtQpu > :first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.fBtQpu > * {
	height: 100%;
	border: 1px solid rgb(48, 48, 48);
	border-radius: 0px;
	padding: 5px 12px;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	z-index: 1;
	margin-right: -1px;
}
.gjQcsA {
	height: 100%;
	border: 1px solid rgb(48, 48, 48);
	display: flex;
	padding: 0px 8px;
	-webkit-box-align: center;
	align-items: center;
	border-radius: 4px;
}
button {
	margin: 0;
	border: none;
	cursor: pointer;
	padding: 0;
	background: none;
}
.gOoTJy {
	animation: 2s ease-in-out 0s 1 normal forwards paused hccjqZ;
}
@media (max-width: 1920px) .hFzgGl {
	font-size: 12px;
	line-height: 14px;
}
.hFzgGl {
    color: rgb(146, 146, 146);
    text-transform: uppercase;
}
@media (max-width: 1920px)
.ilBdOD {
    font-size: 12px;
    line-height: 14px;
}
.ilBdOD {
    color: rgb(146, 146, 146);
    padding-right: 8px;
}
.BHLgi {
    transition: transform 150ms ease-in-out 0s;
}
.iNoznw {
    width: max-content;
    height: max-content;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
}
.BHLgi svg {
    transform: scale(1.2);
}
.fBtQpu > :last-child {
    margin-right: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.fBtQpu > * {
    height: 100%;
    border: 1px solid rgb(48, 48, 48);
    border-radius: 0px;
    padding: 5px 12px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 1;
}
.wohgQ {
    height: 100%;
    border: 1px solid rgb(48, 48, 48);
    padding: 0px 8px;
    border-radius: 4px;
}
a {
    color: inherit;
    text-decoration: none;
}
@media (max-width: 1920px)
.eULCUb {
    font-size: 12px;
    line-height: 14px;
}
.eULCUb {
    color: rgb(255, 135, 0);
}
.hbhzdg {
    width: auto;
    height: auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
    justify-content: normal;
}
.FPZZh {
    width: 20px;
    height: 20px;
    overflow: hidden;
    border-radius: 50%;
}
.FPZZh img {
    width: 100%;
    height: 100%;
}
.jgJoCM {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}
@media (max-width: 1920px)
.ecJMWo {
    font-size: 12px;
    line-height: 14px;
}
.ecJMWo {
    color: rgb(249, 82, 0);
    padding: 16px;
}
.dVRKAF {
    width: 100%;
    height: 1px;
    border: none;
    min-height: 1px;
    background: rgb(48, 48, 48);
    margin-top: 0px;
    margin-bottom: 0px;
}