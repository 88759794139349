.select-box {
	height: max-content;
	min-height: 30px;
	border-radius: 8px;
	border: 1px solid #ccc;
	background: transparent;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	cursor: pointer;
}
.selected-box {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 12px;
	min-height: 30px;
	padding: 3px;
}
.selected-item {
	padding: 3px;
	margin: 2px;
	border-radius: 5px;
	background: #5e5d5d;
	color: white;
	white-space: nowrap;
	cursor: pointer;
}
.option-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px;
}
.option-item:not(:last-child) {
	border-bottom: 1px solid grey;
}
.option-box {
	border: 1px solid #ccc;
	border-radius: 8px;
	max-height: 150px;
	overflow: auto;
	height: max-content;
	position: absolute;
	width: -webkit-fill-available;
	z-index: 99;
	top: 1px;
	background: #080404;
}
.item-info {
	display: flex;
	align-items: center;
	font-size: 12px;
}
.eth {
	border-radius: 8px;
	background: #383535;
	padding: 3px;
	margin-left: 3px;
}
.edit {
	display: flex;
	flex-direction: row;
}
.add-wallet {
	padding-top: 10px;
	padding-left: 10px;
	max-height: 150px;
	overflow: auto;
	height: 20px;
	width: -webkit-fill-available;
	z-index: 99;
	background: #1f2330;
	cursor: pointer;
}
.add-input {
	width: -webkit-fill-available;
	height: 20px;
	user-select: none;
	background: #080404;
	border: none;
	color: grey;
	padding: 5px;
	padding-left: 10px;
	border-bottom: 1px solid grey;
	outline: none;
}
