
.profilesContainer {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh;

  .iconCircle {
    position: absolute;
    top: -12px;
    right: -5px;
    background-color: #3a3a3a; /* Circle background color */
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

}

.profileCard {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px 10px 10px 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    position: relative; /* To position the close icon absolutely within */

}

.closeIcon {
  position: absolute;
  top: -12px;
  right: -10px;
  background-color: #000; /* Red background for delete */
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d32f2f; /* Darker red on hover */
  }
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: dashed 1px #fff;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.profileHeaderAutobuys {
    display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}

.profileTitle {
  font-size: 13px;
    color: #7fa6ff; /* Brighter blue-purple */
    text-shadow: 0 0 8px rgba(127, 166, 255, 0.8), 0 0 14px rgba(90, 120, 230, 0.6); /* Stronger blue-purple glow */



}

.iconContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  
}

.toggleInput {
  display: none;
}

.toggledOn {
  width: 45px;
  height: 20px;


background-color: #4cfaff;
box-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);



  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.1s ease, box-shadow 0.1s ease;

  .toggleIcon {
    left: 26px; /* Position for toggled on */
  }
}

.toggledOff {
  width: 45px;
  height: 20px;
  background-color: #ccc; /* Color when toggled off */
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.1s ease, box-shadow 0.1s ease;
  box-shadow: none; /* No glow when toggled off */

  .toggleIcon {
    left: 2px; /* Position for toggled off */
  }
}

.toggleIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 5px;
  top: 2px;
  transition: left 0.3s ease;
}

.customToast {
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
    color: #4cfaff;
    padding-left: 15px;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}

.value {
  font-size: 13px;
  color: #fff;
}

.bag {
    color: #aecbff; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
}

.highlight {
  color: #28c0ff;
}

.profileContainer {
  color: #ddd;
  border-radius: 8px;
}

.profileRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
