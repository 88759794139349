.container {
  margin-top: 12px;

  .title {
    font-size: 12px;
    color: gray;
  }

  .accordions {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 16px;

    .selectAll {
      display: flex;
      justify-content: start;
      font-size: 12px;
      align-items: center;
      gap: 6px;
      border-radius: 4px;
      font-weight: 500;
      color: rgb(246, 174, 45);
      padding: 0;
      margin-top: 12px;

      &.selected {
        color: red;
      }
    }

    .items {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      padding: 0;

      .item {
        display: flex;
        align-items: center;
        padding: 10px;
        border-top: 1px dashed rgb(48, 48, 48);
        gap: 12px;
        cursor: pointer;

        &:last-child {
          border-bottom: 1px dashed rgb(48, 48, 48);
        }

        .itemDesc {
          margin-top: 4px;
          color: gray;
          font-size: 14px;
        }

        .itemCheckIcon {
          border-radius: 100%;
          border: 1px solid rgb(246, 174, 45);
          min-width: 20px;
          min-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.itemCheck {
          .itemCheckIcon {
            background: rgb(246, 174, 45);
          }

          background: #121418;
        }
      }
    }
  }
}
